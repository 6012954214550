import * as React from "react"
import Layout from '../components/layout'

const LicensePage = () => {
  return (
    <Layout pageTitle="G13 Railway Operations Model - License">
      <p><strong>LICENCE</strong></p>
      <p>A licence to use the ROM is now offered to those for whom this would be a viable investment.   A licence provides for:</p>
      <ul>
        <li>Corporate use limited to a defined  business entity and  its employees;</li>
        <li>A lump sum for a licence in perpetuity or a monthly fee convertible to in perpetuity or surrender with 3 months notice;</li>
        <li>Availability, to all clients, of all upgrades arranged by G13 on its own behalf or for a client; and</li>
        <li>Post warranty support on a fee for service basis;</li>
      </ul>
    </Layout>
  )
}

export default LicensePage
